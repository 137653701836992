<template>
  <v-container>
    <v-row>
      <v-col>
        <p class="text-h2">You have logged out!</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LOGOUT } from "@/store/actions.type";

export default {
  name: "LogoutPage",

  created() {
    this.$store.dispatch(`auth/${LOGOUT}`).then(() => {
      this.$router.replace({
        name: "LoginPage",
      });
    });
  }
}
</script>
